import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import useVisibility from "../../../CustomHooks/useVisibility";
import { Lockout } from "../../../Interfaces/Lockout";
import { TokenContext } from "../../../Providers/TokenProvider";
import { cxURL } from "../../../utils/constants";
import LockoutMessage from "./LockoutMessage";

const LockoutController: FunctionComponent = ({ children }) => {
  const { eventId, assessment, returnUrl } = useContext(TokenContext);
  const TestEvent = useContext(TestEventContext);
  const currentLockoutState = useRef(TestEvent.lockout);
  const { isHidden, refocus } = useVisibility();

  useEffect(() => {
    const sendLockout = async () => {
      const response = await postLockout(eventId, assessment);
      const newLockout = (await response.json()) as Lockout;
      if (newLockout.isLockout) {
        const lockoutLocation = new URL(returnUrl);
        window.location.replace(
          lockoutLocation.origin + lockoutLocation.pathname
        );
      } else {
        TestEvent.lockout = newLockout;
      }
    };
    currentLockoutState.current = {
      ...TestEvent.lockout,
      lockout: TestEvent.lockout.lockout + 1,
      isLockout: TestEvent.lockout.lockout + 1 >= 5
    };
    if (isHidden && TestEvent.useLockout && !TestEvent.hideOnInit) {
      sendLockout();
    }
  }, [TestEvent, assessment, eventId, isHidden, returnUrl]);

  useEffect(() => {
    const unloadHandler = () => {
      if (TestEvent.useLockout) {
        postLockout(eventId, assessment);
      }
    };
    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [TestEvent, assessment, eventId]);

  return isHidden && TestEvent.useLockout ? (
    <LockoutMessage lockout={currentLockoutState.current} refocus={refocus} />
  ) : (
    children
  );
};

async function postLockout(
  eventId: string | null,
  assessment: string
): Promise<Response> {
  return await fetch(`${cxURL}/e/${eventId}/lockout`, {
    method: "POST",
    body: JSON.stringify({ assessment }),
    keepalive: true
  });
}

export default LockoutController;
